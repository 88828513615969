<template>
  <div class="th-list-course">
    <h2 class="th-title"><b> Data Center Dashboard</b></h2>
    <div class="row px-3 pt-3 justify-content-between" v-if="false">
      <div class="card col-xl-3 d-inline-block p-1">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <div class="mr-auto">
              <p class="text-uppercase text-dark font-weight-bold mb-1">Active Assets</p>
              <p class="text-gray-5 mb-0">Aset dengan garansi aktif</p>
            </div>
            <p class="text-success font-weight-bold font-size-24 mb-0">251</p>
          </div>
        </div>
      </div>
      <div class="card col-xl-4 d-inline-block p-1">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <div class="mr-auto">
              <p class="text-uppercase text-dark font-weight-bold mb-1">Expiring Assets</p>
              <p class="text-gray-5 mb-0">Garansi akan berakhir dalam 3 bulan</p>
            </div>
            <p class="text-warning font-weight-bold font-size-24 mb-0">24</p>
          </div>
        </div>
      </div>
      <div class="card col-xl-4 d-inline-block p-1">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <div class="mr-auto">
              <p class="text-uppercase text-dark font-weight-bold mb-1">Expired Assets</p>
              <p class="text-gray-5 mb-0">Aset dengan garansi berakhir</p>
            </div>
            <p class="text-danger font-weight-bold font-size-24 mb-0">10</p>
          </div>
        </div>
      </div>
    </div>
    <a-card>
      <div class="mb-3 row">
        <div class="col-3 m-1">
          <a-input-search placeholder="Cari Nama Ruangan..." v-model:value="search" />
        </div>
        <!-- <div class="col-2 m-1">
          <a-select ref="selectedFilterLokasi" v-model:value="filterLokasi" style="width: 220px">
            <a-select-option v-for="(item, i) in filterLokasi" :key="i" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-2 m-1 ml-5">
          <a-select ref="select" v-model:value="selectedIndukPerusahaan" style="width: 220px">
            <a-select-option v-for="(item, i) in selectedIndukPerusahaan" :key="i" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </div> -->
        <div class="col m-1 w-100 d-flex justify-content-end align-items-center">
          <a-button @click="$router.push({ name: 'Buat Base Ruangan' })">
            <PlusOutlined />Ruangan Baru
          </a-button>
        </div>
      </div>
      <div class="w-100" style="overflow-x:auto ;">
        <a-table :pagination="globalPagination" :columns="columns" :data-source="filteredData" class="table" bordered
          table-layout="auto" :loading="loadingTable" @change="handleTableChange">
          <template #tanggal="{ }">
            {{ getDate }}
          </template>
          <!-- <template #customer="{text}">

          </template> -->
          <template #customer="{ record }">
            <router-link class="th-link" :to="{
              name: 'Detail Base Ruangan',
              params: { id: record.id },
            }">
              {{ record.Customer.name }}
            </router-link>
            <p class="text-mute">
              {{ record.Customer.customerSign }}
            </p>
          </template>
          <template #ruangan="{ record }">
            <b>
              <HomeOutlined></HomeOutlined>
              {{ record.name }}
            </b>

            <br />
            <span>Kategori ruangan: </span>
            <small>
              {{ record.type }}
            </small>
          </template>
          <template #location="{ record }">
            {{ record.Location.name }}
          </template>
        </a-table>
        <!-- <a-pagination
          v-if="!isSearching"
          @change="onChangePage"
          class="float-right mt-3"
          :total="totalPages"
        /> -->
      </div>
    </a-card>
  </div>
</template>

<script>
/* eslint-disable */
import { onMounted, ref, reactive, createVNode, toRef, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { baseURL } from '@/services/axios'
import {
  CheckOutlined,
  ToolOutlined,
  PhoneOutlined,
  SearchOutlined,
  PlusOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  ExclamationCircleOutlined,
  CloseSquareOutlined,
  InfoCircleOutlined,
  HomeOutlined,
} from '@ant-design/icons-vue'
import rupiahPrice from '@/helpers/rupiahPrice'
import {
  getAddress,
  getAll,
  searchPotensi,
  remove,
  updateNasabah,
  getAllKanwil,
  getOneKC,
  getDistinct,
} from '@/services/axios/brivice-backend'
import fuzzysort from 'fuzzysort'
import { Modal, notification, message } from 'ant-design-vue'
import { useForm } from '@ant-design-vue/use'
import axios from 'axios'
import moment from 'moment'
import lodash from 'lodash'
import assetsFile from '@/data/modc_dump.json'
import { randomUUID } from '@/helpers/randomUUID'
import { getRooms } from '../../../services/axios/modc/customer'

const columns = [
  {
    title: 'CUSTOMER',
    dataIndex: 'customer',
    className: '',
    key: 'customer',
    slots: { customRender: 'customer' },
    width: 150,
    // sorter: true,
  },
  {
    title: 'LOCATION',
    dataIndex: 'location',
    className: '',
    key: 'location',
    slots: { customRender: 'location' },
    // width: 100,
    // sorter: true,
  },
  {
    title: 'RUANGAN',
    dataIndex: 'ruangan',
    className: '',
    key: 'ruangan',
    slots: { customRender: 'ruangan' },
  },

  // {
  //   title: 'ASSETS',
  //   dataIndex: 'assets',
  //   className: '',
  //   key: 'assets',
  //   slots: { customRender: 'assets' },
  // },
  // {
  //   title: 'SOLVING',
  //   dataIndex: 'solving',
  //   className: '',
  //   key: 'solving',
  //   slots: { customRender: 'solving' },
  //   filters: [
  //     {
  //       text: 'Reparation',
  //       value: 'Reparation',
  //     },
  //     {
  //       text: 'Part Replacement',
  //       value: 'Part Replacement',
  //     },
  //     {
  //       text: 'Reconfiguration',
  //       value: 'Reconfiguration',
  //     },
  //   ],
  //   // filters: ['Reparation', 'Part Placement', 'Reconfiguration'],
  //   onFilter: (value, record) => {
  //     return record.solving == value
  //   },
  // },
  // {
  //   title: 'STATUS',
  //   dataIndex: 'state',
  //   className: '',
  //   key: 'state',
  //   slots: { customRender: 'state' },
  //   filters: [
  //     {
  //       text: 'Complated',
  //       value: 'Complated',
  //     },
  //     {
  //       text: 'Pending',
  //       value: 'Pending',
  //     },
  //   ],
  //   // filters: ['Reparation', 'Part Placement', 'Reconfiguration'],
  //   onFilter: (value, record) => {
  //     return record.state == value
  //   },
  // },
]

export default {
  components: {
    CloudUploadOutlined,
    CloudDownloadOutlined,
    InfoCircleOutlined,
    CheckOutlined,
    ToolOutlined,
    PhoneOutlined,
    HomeOutlined,
    PlusOutlined,
  },
  setup() {
    /* eslint-disable */
    let store = useStore()
    // console.log(store.state.user, 'usercourse')

    let courseData = ref([])
    let modalPopUpVisibleBrispot = ref(false)
    let modalPopUpVisibleKurasi = ref(false)
    let selectedKanwil = ref(null)
    let isNasabah = ref(false)
    selectedKanwil.value = 'all'
    let potensiData = ref([])
    let indukPerusahaan = ref([])
    let isSearching = ref(false)
    let totalPages = ref([])
    let globalPagination = ref({ current: 1, pageSize: 10 })
    let search = ref('')

    //
    let selectedFilterColumns = ref([
      'ID',
      'TANGGAL',
      'LOKASI',
      'MANUFAKTUR',
      // 'STATUS GARANSI',
      // 'NILAI ASET',
    ])
    let selectedColumns = ref([
      'ID',
      'TANGGAL',
      'LOKASI',
      'MANUFAKTUR',
      // 'STATUS GARANSI',
      // 'NILAI ASET',
    ])
    const handleChangeColumn = e => {
      columns.forEach(col => {
        if (col.title !== 'AKSI') {
          if (!e.includes(col.title)) {
            col.className = 'd-none '
          } else {
            col.className = ''
          }
        }
      })
    }

    //

    let filterLokasi = [
      'Filter Lokasi',
      'DC Salemba',
      'DC Depok',
      'Headquarter',
      'DC Graha Mustika',
      'Bakrie Center',
    ]
    let selectedFilterLokasi = ref('Filter Lokasi')
    let filterRuang = ref(['Filter Ruang', 'Network Operation', 'Main Genset', 'Ruang Testing'])
    let selectedIndukPerusahaan = [
      'Filter Status',
      'Garansi Aktif',
      'Segera Berakhir',
      'Garansi Habis',
    ]
    let selectedIndukPerusahaanExcel = ref('all')
    let selectedIndukPerusahaanExcelKurasi = ref('all')
    let kanwilData = ref([])
    const acc_token = ref(null)
    let filteredData = ref([])
    let loadingTable = ref(false)
    let sortRef = ref(null)
    let filter = reactive({
      name: null,
    })
    let pageRef = ref(null)
    let modalAddVisible = ref(false)
    const loadingAdd = ref(false)
    const router = useRouter()
    onMounted(async () => {
      fetchCourse()
    })

    const onChangePage = page => {
      if (!isSearching.value) {
        fetchCourse(page)
      }
    }

    const onFilterMenuChange = value => {
      if (value) {
        console.log(potensiData.value, '<<<<<<<<<<<<<')
        // filteredData.value = potensiData.value.filter(d => d.indukPerusahaan === value)
        fetchCourse({ filter: value })
      } else {
        // filteredData.value = potensiData.value
        fetchCourse()
      }
      // potensiData.value = potensiList.result.filter(d => d.status === 'active')
    }

    const rulesRef = reactive({
      name: [
        {
          required: true,
          message: 'Nama harus diisi!',
          trigger: 'change',
        },
      ],
      categoryId: [
        {
          required: true,
          message: 'Kategori harus diisi!',
          trigger: 'change',
        },
      ],
      vendorId: [
        {
          required: true,
          message: 'Vendor harus diisi!',
          trigger: 'change',
        },
      ],
    })
    let statusPublication = ref('all')
    let vendors = ref([])
    let categories = ref([])
    let courseGeneralForm = reactive({
      name: null,
      ruangan: null,
      price: 1,
      type: 'free',
      vendorId: null,
      categoryId: null,
    })

    const onSearch = async searchText => {
      if (searchText !== '') {
        isSearching.value = true
        loadingTable.value = true
        const res = await searchPotensi(searchText)
        console.log(res, 'res search')
        filteredData.value = res.result
        loadingTable.value = false
      } else {
        isSearching.value = false
        loadingTable.value = false
        fetchCourse(1)
      }
    }

    const deletePotensi = id => {
      Modal.confirm({
        title: `Apa anda yakin ingin melakukan perawatan?`,
        icon: createVNode(ExclamationCircleOutlined),
        async onOk() {
          try {
            await remove(id)
            // await store.dispatch('course/DELETE_COURSE', { idCourse })
            // router.push({ name: 'Course' })
            fetchCourse(1)
            notification.success({
              message: `Berhasil Menghapus Potensi.`,
            })
          } catch (err) {
            notification.error({
              message: `Gagal Menghapus Potensi.`,
            })
            console.log(err)
          }
        },
        onCancel() { },
      })
    }

    const fetchCourse = async (search = '') => {
      loadingTable.value = true
      const data = await getRooms(search)
      console.log(data, '<<<<<')
      filteredData.value = data.rooms
      loadingTable.value = false
      isSearching.value = false
      globalPagination.value = { ...globalPagination.value, total: data.rooms.length }
    }
    watch(search, (newValue, oldValue) => {
      // Fungsi ini akan berjalan setiap kali myVariable berubah
      // Panggil fungsi atau lakukan tindakan lain yang Anda inginkan di sini
      fetchCourse(search.value);
    });

    const lodashSearch = lodash.debounce(() => fetchCourse(), 1500)
    const handleSearch = () => {
      globalPagination.value.current = 1
      lodashSearch()
    }
    const handleTableChange = (pag, filters, sorter) => {
      globalPagination.value.current = pag.current
      fetchCourse()
    }
    const showModalAdd = () => {
      if (vendors.value.length && categories.value.length) {
        modalAddVisible.value = true
      } else {
        return notification.error({
          message: `Harap membuat kategori dan vendor terlebih dahulu.`,
        })
      }
    }

    const goTo = (name, params) => {
      router.push({ name, params })
    }
    const handleOk = () => {
      modalAddVisible.value = false
    }
    const { resetFields, validate, validateInfos } = useForm(courseGeneralForm, rulesRef)

    return {
      handleSearch,
      search,
      filterLokasi,
      selectedIndukPerusahaan,
      globalPagination,
      columns,
      filteredData,
      loadingTable,
      handleTableChange,
    }
  },
  data() {
    return {
      indukPerusahaan: [],
      assets: assetsFile,
    }
  },
  mounted() {
    this.fetchInduk()
    console.log(this.indukPerusahaan)
  },
  methods: {
    getAsset: function (i) {
      return this.assets[13].data[i + 24]
    },
    getCategoryAsset(cat) {
      return this.assets[15].data.find(da => da.category == cat)
    },
    getLocationAsset(loc) {
      const location = this.assets[47].data.find(da => da.id == loc)
      const customer = this.assets[29].data.find(da => da.customer_id == location.cid)
      return { location, customer }
    },
    getRoomsAssets(room) {
      return this.assets[64].data.find(da => da.id == room)
    },
    getManufacture(manufac) {
      const manufacture = this.assets[50].data.find(da => da.id == manufac)
      return manufacture
    },
    getHumanDate: function (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    getHumanTime: function (date) {
      return moment(date).format('HH:mm')
    },
    getColor: function (acc) {
      return acc > 90 ? 'green' : acc > 60 ? 'orange' : 'red'
    },
    getNameAssets: function (record) {
      return Math.round(
        (record.ahuPercentage ? record.ahuPercentage : record.placePercentage) * 100,
      )
    },
    getPhoneAssets: function (record) {
      if (record.phone && !record.phonePercentage) return 100
      if (record.phone) return record.phonePercentage * 100
      else {
        return 0
      }
    },
    async fetchInduk() {
      const resp = await getDistinct()
      this.indukPerusahaan = resp.result
      // this.selectedIndukPerusahaan = ''
    },
  },
  computed: {
    getDate() {
      return moment().format('DD/MMM/YYYY | HH:mm:ss')
    },
  },
}
</script>
<style lang="scss">
@import '@/views/styleViews.scss';

.text-customer {
  font-size: 0.75rem;
}

.th-list-course {
  .th-link {
    color: var(--vb-color-primary) !important;

    &:hover {
      color: var(--kit-color-primary) !important;
    }
  }
}
</style>
